@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/plugins/jqvmap/jqvmap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';
@import '~admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css';
@import '~admin-lte/plugins/daterangepicker/daterangepicker.css';
@import '~admin-lte/plugins/summernote/summernote-bs4.css';
@import '~admin-lte/plugins/bootstrap-colorpicker/css/bootstrap-colorpicker.min.css';
@import '~admin-lte/plugins/select2/css/select2.min.css';
@import '~admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css';
@import '~admin-lte/plugins/bootstrap4-duallistbox/bootstrap-duallistbox.min.css';
@import '~admin-lte/plugins/fullcalendar/main.min.css';
@import '~admin-lte/plugins/fullcalendar-daygrid/main.min.css';
@import '~admin-lte/plugins/fullcalendar-timegrid/main.min.css';
@import '~admin-lte/plugins/fullcalendar-bootstrap/main.min.css';

.loader {
    .loading {
        top: 35%;
        position: absolute;
        left: 50%;
    }
    position: fixed;
    width: 100%;
    height: -webkit-fill-available;
    background-color: #d1d1d1a3;
    display: block;
    z-index: 1039;
    top: 0px;
    height: 100%;
}


.select2-container .select2-selection--single {
    height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 35px;
}

.select2 .select2-container .select2-container--default {
    width: 100% !important;
}

.money-mask {
    text-align: left !important;
}

.is-invalid {
    .select2-container--default .select2-selection {
        border: 1px solid #dc3545 !important;
    }
}

.input-upload {
    border: 1px solid #ced4da;
    padding: 3px 5px;
    border-radius: .25rem;
    width: 100%;
}

.input-upload.is-invalid {
    border: 1px solid #dc3545 !important;
    padding: 3px 5px;
    border-radius: .25rem;
    width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #007BFF;
}

.form-check-input {
    margin-left: 0 !important;
}

.form-check-label {
    margin-left: 20px !important;
}

.center-table-element {
    vertical-align: middle;
    text-align: center;
}

.widget-product-image {
    img {
        padding-right: 10px;
        float: left;
        width: 200px;
        height: 200px;
    }    
}

.box-checkbox {
    border: 1px solid #ced4da;
    padding: 10px 5px 5px 5px;
    border-radius: .25rem
}

.fake-input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #e9ecef;
    opacity: 1;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    box-shadow: inset 0 0 0 transparent;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.badge-big {
    font-size: 1.5em;
}

.remove-search-filter {
    line-height: 1.8;
    padding-right: 10px;
}

.align-middle td{
    vertical-align: middle;
}

.bg-success-secondary {
    background: #28a74683;
    color: #fff;
}

.bg-d2a-blue {
    background: #4450a2;
}

.bg-d2a-orange {
    background: #f8931f;
}

.text-d2a-orange {
    color: #f8931f;
}

.bg-d2a-orange-secondary {
    background: #f8931f84;
}

.text-d2a-blue {
    font-weight: bold;
    color:#4450a2;
}

.schedule-button-margin {
    margin-bottom: 20px;
}

.center-button {
    text-align: center;
}

.cash-register-total {
    padding: 10px;
    font-weight: bolder;
    text-transform: uppercase;
}

.cash-register-subsidiary {
    margin-bottom: 20px;
    min-width: 250px;
    max-width: 250px;
}

.index-card-title {
    margin-bottom:.75rem;
    float:left;
    font-size:1.1rem;
    font-weight:400;
    margin:0;
    font-size:1rem;
}

.btn-hidden {
    display:none;
}

.whatsapp-float-button{
	position:fixed;
	width:60px;
	height:60px;
	bottom:20px;
	right:20px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    font-size:30px;
	box-shadow: 2px 2px 3px #999;
    z-index:0;
}

.icon-whatsapp-float-button{
	margin-top:16px;
}

.mb-40 {
    margin-bottom: 45px;
}

.prescription {
    width: 794px;
    height: 1123px;
    margin: 0 auto;
}

.prescription tr > td {
    padding: 15px;
}

.header {
    color: #333;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.medicine {
    display: flex;
    flex-flow: column;
    height: 100%;
    white-space: pre-wrap;
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top:auto; 
}

.allButFooter {
    min-height: calc(100vh - 40px);
}

.logo img {
    width: 128px;
    float: left;
}

.credentials {
    flex: 1;
}

.credentials h4 {
    line-height: 1em;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
}

.credentials p {
    margin: 0 0 5px 0;
    padding: 3px 0px;
}

.credentials small {
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    padding-right: 80px;
}

hr {
    margin: 10px 0px !important;
}

@media only screen and (max-width: 768px) {
    [class*="index-card-title"] {
      margin-bottom: 10px;
    }
}

.prescription-controlled-content {
    display: none;
}

.dropdown-clinic ul {
    width: 250px;
}

.accommodation-quantity,
.accommodation-quantity input {
    width: 80px;
}

.btn-app-no-margin {
    margin: 0 !important;
}